import Login from "features/userManagement/components/Login.js";
import Dashboard from "features/dashboard/components/Dashboard";
import InventoryAddPage from "features/inventory/components/InventoryAddPage";
import InventoryListPage from "features/inventory/components/InventoryListPage";
import CustomerDetailPage from "features/crm/subFeatures/contactDetailPage/CustomerDetailPage";
import CustomerListPage from "features/crm/subFeatures/contactListPage/CustomerListPage";
import CustomerAddPage from "features/crm/components/CustomerAddPage";
import InventoryDetailsPage from "features/inventory/components/InventoryDetailPage";
import DealListPage from "features/deal/components/DealListPage";
import DealDetailPage from "features/deal/components/DealDetailPage";
import SettingsPage from "features/settings/components/SettingsPage";
import ReportsPage from "features/reports/ReportsPage";
import InventoryAdvertisingPage from "features/inventory/components/InventoryAdvertisingPage";
import InventoryAppraisalPage from "features/inventory/components/InventoryAppraisalPage";
import VendorPage from "features/settings/subFeatures/vendor/VendorPage";
import { PATHS } from "constants/Constants";
import PaymentTerminalPage from "services/digitzsService/PaymentTerminalPage";
import FormGen from "features/forms/components/FormGen";
import MultiDealer from "features/forms/components/MultiDealer";

import DealAddPage from "features/deal/components/DealAddPage";
import QuickQuoteDeal from "features/deal/subFeatures/quickQuote/QuickQuoteDeal";
import { useFromLocations, useFromUser } from "../stores/LocalStorageHelper";
import AccountListPage from "../account/AccountListPage";
import AccountDetailPage from "../account/AccountDetailPage";
import InventorySearchList from "../features/deal/subFeatures/inventory/InventorySearchList";
import DealSearchList from "../features/deal/components/DealSearchList";
import OnlineLeadDetailsPage from "../features/crm/subFeatures/onlineLeadDetailsPage/OnlineLeadDetailsPage";

const usePublicRoutes = () => {
	const locationID = useFromUser("locationID");
	let bhph = 0;
	bhph = useFromLocations("fn");
	// if ([4757, 6730, 7942, 5456, 7906, 7464, 7664, 4575].includes(locationID)) {
	// 	bhph = 1;
	// } else {
	// 	bhph = 0;
	// }

	// if (locationID === 4757 || locationID === 6730) bhph = 1;
	// else bhph = 0;
	console.log({ bhph });
	return [
		{
			path: PATHS.DASHBOARD,
			name: "Dashboard",
			icon: "nc-icon nc-layout-11",
			component: Dashboard,
			layout: "",
		},
		{
			path: PATHS.LOGIN,
			name: "Login",
			icon: "nc-icon nc-simple-add",
			component: Login,
			layout: "",
			invisible: true,
		},
		{
			collapse: true,
			name: "Deals",
			icon: "nc-icon nc-briefcase-24",
			state: "dealCollapse",
			views: [
				{
					path: PATHS.DEAL_LIST,
					name: "View Deals",
					icon: "nc-icon nc-bullet-list-67",
					component: DealListPage,
					layout: "",
				},
				{
					path: PATHS.DEAL_ADD,
					name: "New Deal",
					icon: "nc-icon nc-simple-add",
					component: DealAddPage,
					layout: "",
				},
				{
					path: PATHS.QuickQuote,
					name: "Quick Quote",
					icon: "nc-icon nc-sound-wave",
					component: QuickQuoteDeal,
					layout: "",
				},
				{
					path: PATHS.DEAL_DETAIL,
					name: "Deal Detail",
					component: DealDetailPage,
					layout: "",
					invisible: true,
				},
				{
					path: PATHS.DEAL_SEARCH_LIST,
					name: "Quick Search",
					icon: "nc-icon nc-zoom-split",
					component: DealSearchList,
					layout: "",
					invisible: false,
				},
			],
		},
		{
			collapse: true,
			name: "CRM",
			icon: "nc-icon nc-single-02",
			state: "contactCollapse",
			views: [
				{
					path: PATHS.CUSTOMER_LIST,
					name: "View List",
					icon: "nc-icon nc-bullet-list-67",
					component: CustomerListPage,
					layout: "",
				},
				{
					path: PATHS.CUSTOMER_ADD,
					name: "Add Customer",
					icon: "nc-icon nc-simple-add",
					component: CustomerAddPage,
					layout: "",
				},
				{
					path: PATHS.LEAD_ADD,
					name: "Add Lead",
					icon: "nc-icon nc-simple-add",
					component: OnlineLeadDetailsPage,
					layout: "",
				},
				{
					path: PATHS.CUSTOMER_DETAIL,
					name: "Customer Detail",
					component: CustomerDetailPage,
					layout: "",
					invisible: true,
				},
				{
					path: PATHS.LEAD_DETAIL,
					name: "Lead Detail",
					component: CustomerDetailPage,
					layout: "",
					invisible: true,
				},
				// {
				// 	path: PATHS.CUSTOMER_SEARCH_LIST,
				// 	name: "Quick Search",
				// 	icon: "nc-icon nc-search-icon",
				// 	component: InventorySearchList,
				// 	layout: "",
				// 	invisible: false,
				// },
			],
		},
		// {
		// 	collapse: true,
		// 	name: "CRM",
		// 	icon: "fa fa-comments",
		// 	state: "communicationsCollapse",
		// 	views: [
		// 		{
		// 			path: PATHS.MESSAGING,
		// 			name: "Message",
		// 			icon: "nc-icon nc-bullet-list-67",
		// 			component: CommunicationListPage,
		// 			layout: "",
		// 		},
		// 	],
		// },
		{
			collapse: true,
			name: "Inventory",
			icon: "nc-icon nc-delivery-fast",
			state: "inventoryCollapse",
			views: [
				{
					path: PATHS.INVENTORY_LIST,
					name: "View Inventory",
					icon: "nc-icon nc-bullet-list-67",
					component: InventoryListPage,
					layout: "",
				},
				{
					path: PATHS.INVENTORY_ADD,
					name: "Add Inventory",
					icon: "nc-icon nc-simple-add",
					component: InventoryAddPage,
					layout: "",
				},
				{
					path: PATHS.INVENTORY_ADVERTISING,
					name: "Advertising",
					icon: "nc-icon nc-world-2",
					component: InventoryAdvertisingPage,
					layout: "",
				},
				{
					path: PATHS.INVENTORY_APPRAISAL,
					name: "Appraisal",
					icon: "nc-icon nc-tag-content",
					component: InventoryAppraisalPage,
					layout: "",
				},
				{
					path: PATHS.INVENTORY_DETAIL,
					name: "Inventory Details",
					component: InventoryDetailsPage,
					layout: "",
					invisible: true,
				},
				{
					path: PATHS.INVENTORY_SEARCH_LIST,
					name: "Quick Search",
					icon: "nc-icon nc-zoom-split",
					component: InventorySearchList,
					layout: "",
					invisible: false,
				},
			],
		},
		{
			collapse: true,
			name: "BHPH",
			icon: "nc-icon nc-briefcase-24",
			state: "bhphCollapse",
			invisible: bhph === 0 ? true : false,
			//invisible: true,
			views: [
				{
					path: PATHS.ACCT_LIST,
					name: "View Acounts",
					icon: "nc-icon nc-bullet-list-67",
					component: AccountListPage,
					layout: "",
				},
				{
					path: PATHS.ACCT_DETAIL,
					name: "View Details",
					icon: "nc-icon nc-bullet-list-67",
					component: AccountDetailPage,
					layout: "",
					invisible: true,
				},
				// {
				//  path: PATHS.BHPH_ADD,
				//  name: "New Account",
				//  icon: "nc-icon nc-simple-add",
				//  component: AccountAddPage,
				//  layout: "",
				// },
			],
		},
		{
			path: PATHS.REPORTS,
			name: "Reports",
			icon: "nc-icon nc-chart-bar-32",
			component: ReportsPage,
			layout: "",
		},
		{
			path: PATHS.VENDORS,
			name: "Companies",
			icon: "nc-icon nc-circle-10",
			component: VendorPage,
			layout: "",
		},
		{
			path: PATHS.PAYMENT_TERMINAL,
			name: "Payments",
			icon: "nc-icon nc-credit-card",
			component: PaymentTerminalPage,
			layout: "",
		},
		{
			path: PATHS.SETTINGS,
			name: "Settings",
			icon: "nc-icon nc-settings-gear-65",
			component: SettingsPage,
			layout: "",
		},
		{
			path: PATHS.FormGen,
			name: "FormGen",
			icon: "nc-icon nc-layout-11",
			component: FormGen,
			layout: "",
			invisible: true,
		},
		{
			path: PATHS.MultiDealer,
			name: "MultiDealer",
			icon: "nc-icon nc-layout-11",
			component: MultiDealer,
			layout: "",
			invisible: true,
		},
	];
};

export default usePublicRoutes;
