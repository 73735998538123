import React from "react";
import { Col, Row, Label, Form } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import tabOnEnter from "../utils/formNavigationUtils";
import InputElement, {
	CurrencyInput,
} from "../features/forms/components/InputElement";
import dayjs from "dayjs";

const AccountBalance = () => {
	const { account } = useCurrentAccountStore((state) => state, shallow);

	return (
		<>
			<Col className="pb-3 px-0 d-flex align-items-center justify-content-center">
				<div>
					<h4>Balance({dayjs(account.lastUpdateDate).format("MM/DD/YYYY")})</h4>
				</div>
			</Col>

			<Form>
				<Row className="mx-0 pt-0">
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label></Label>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label>Total</Label>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label>Paid To Date</Label>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label>Remainder</Label>
					</Col>
				</Row>

				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label>Principle (Loan)</Label>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.amtFinanced}
							readOnly={true}
							name="amtFinanced"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.principle}
							readOnly={true}
							name="principle"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.principleRemaining}
							readOnly={true}
							name="principleRemaining"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
				</Row>

				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label>Finance Charge</Label>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.financeCharge}
							readOnly={true}
							name="financeCharge"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.interest}
							readOnly={true}
							name="interest"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.financeCharge - account.interest}
							readOnly={true}
							name="remFinanceCharge"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
				</Row>

				{account.isTaxDeferred === 1 && (
					<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
						<Col xs="12" md="4" lg="3" className="mb-2">
							<Label>Tax</Label>
						</Col>
						<Col xs="12" md="4" lg="3" className="mb-2">
							<CurrencyInput
								value={account.tax}
								readOnly={true}
								name="tax"
								type="Currency"
								disableValidation
								colSize="col-xl-12" // Larger column size
							/>
						</Col>
						<Col xs="12" md="4" lg="3" className="mb-2">
							<CurrencyInput
								value={account.taxPaid}
								readOnly={true}
								name="taxPaid"
								type="Currency"
								disableValidation
								colSize="col-xl-12" // Larger column size
							/>
						</Col>
						<Col xs="12" md="4" lg="3" className="mb-2">
							<CurrencyInput
								value={account.tax - account.taxPaid}
								readOnly={true}
								name="remainTax"
								type="Currency"
								disableValidation
								colSize="col-xl-12" // Larger column size
							/>
						</Col>
					</Row>
				)}

				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label>Adjustment</Label>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.debitCreditTotal}
							readOnly={true}
							name="debitCreditTotal"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.adjPaid}
							readOnly={true}
							name="adjPaid"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.totalDebitCredit}
							readOnly={true}
							name="totalDebitCredit"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
				</Row>

				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label>Late Fee</Label>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.totalLateFees}
							readOnly={true}
							name="totalLateFees"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.latePaid}
							readOnly={true}
							name="lateFeesPaid"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.lateFeeDue}
							readOnly={true}
							name="lateFeeDue"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
				</Row>

				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label>Deferred (Pickup)</Label>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.pickupTotal}
							readOnly={true}
							name="pickupTotal"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.pickupPaid}
							readOnly={true}
							name="pickupPaid"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={account.pickupTotal - account.pickupPaid}
							readOnly={true}
							name="pickupRemain"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
				</Row>
				{account.penaltyInt > 0 && (
					<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
						<Col xs="12" md="4" lg="3" className="mb-2">
							<Label>Additional Interest </Label>
						</Col>
						<Col xs="12" md="4" lg="3" className="mb-2">
							<CurrencyInput
								readOnly={true}
								type="Currency"
								disableValidation
								colSize="col-xl-12" // Larger column size
							/>
						</Col>
						<Col xs="12" md="4" lg="3" className="mb-2">
							<CurrencyInput
								value={account.earnedInterest}
								readOnly={true}
								name="earnedInterest"
								type="Currency"
								disableValidation
								colSize="col-xl-12" // Larger column size
							/>
						</Col>
						<Col xs="12" md="4" lg="3" className="mb-2">
							<CurrencyInput
								readOnly={true}
								type="Currency"
								disableValidation
								colSize="col-xl-12" // Larger column size
							/>
						</Col>
					</Row>
				)}

				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<Label>Total</Label>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={
								account.amtFinanced +
								account.financeCharge +
								account.debitCreditTotal +
								account.totalLateFees +
								account.pickupTotal
							}
							readOnly={true}
							name="totalToPay"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={
								account.principle +
								account.interest +
								account.adjPaid +
								account.latePaid +
								account.pickupPaid
							}
							readOnly={true}
							name="totalPayToDate"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
					<Col xs="12" md="4" lg="3" className="mb-2">
						<CurrencyInput
							value={
								account.amtFinanced +
								account.financeCharge +
								account.debitCreditTotal +
								account.totalLateFees +
								account.pickupTotal -
								(account.principle +
									account.interest +
									account.adjPaid +
									account.latePaid +
									account.pickupPaid)
							}
							readOnly={true}
							name="totalRemainder"
							type="Currency"
							disableValidation
							colSize="col-xl-12" // Larger column size
						/>
					</Col>
				</Row>

				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					{/* Label Column */}
					<Col xs="12" md="2" lg="2" className="mb-2">
						<Label>Break Even</Label>
					</Col>

					{/* Date, Cost, and Break Even Inputs */}
					<Col xs="12" md="10" lg="10" className="mb-2">
						<Row>
							{/* Date Input */}
							<Col xs="12" md="4" lg="4" className="mb-2">
								<InputElement
									disableValidation
									readOnly={true}
									value={account.breakEven}
									name="breakEvenDate"
									label="Date"
									type="date"
									colSize="col-12"
								/>
							</Col>

							{/* Cost Input */}
							<Col xs="12" md="4" lg="4" className="mb-2">
								<CurrencyInput
									disableValidation
									label="Cost"
									readOnly={true}
									type="currency"
									name="cost"
									value={account.totalCost}
									colSize="col-12"
								/>
							</Col>

							{/* Break Even Input */}
							<Col xs="12" md="4" lg="4" className="mb-2">
								<CurrencyInput
									disableValidation
									label="Break Even"
									readOnly={true}
									type="currency"
									name="breakEven"
									value={
										account.totalCost - account.principle > 0
											? account.totalCost - account.principle
											: 0
									}
									colSize="col-12"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</>
	);
};

export default AccountBalance;
