import classnames from "classnames";
import matchSorter from "match-sorter";
import PropTypes from "prop-types";
import React, { memo, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from "react-table";
import { Button, FormGroup, Input } from "reactstrap";

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return <input type="checkbox" ref={resolvedRef} {...rest} />;
	}
);

/**
 * ReactTable component for rendering a table with filtering, sorting, and pagination.
 * @param {Object} props - Component props.
 * @param {Array} props.columns - Column configuration.
 * @param {Array} props.data - Data to display in the table.
 * @param {Array} props.vehicleHiddenColumns - Hidden columns for vehicles.
 * @param {Array} props.customerHiddenColumns - Hidden columns for customers.
 * @param {Array} props.hiddenColumns - Initially hidden columns.
 * @param {string} props.customerColumn - Column for customers.
 * @param {string} props.vehicleColumn - Column for vehicles.
 * @param {boolean} props.isDealTable - Indicates if it's a deal table.
 * @param {boolean} props.checkboxColumn - Indicates if checkboxes are shown.
 * @param {function} props.setSelectedRows - Callback for selected rows.
 */
const ReactTable = memo(
	({
		columns,
		onRowClick,
		onRowDoubleClick,
		data,
		vehicleHiddenColumns = [],
		customerHiddenColumns = [],
		hiddenColumns = [],
		customerColumn,
		vehicleColumn,
		isDealTable = false,
		checkboxColumn,
		setSelectedRows,
		conditionalRow = () => "",
	}) => {
		const CHECKBOX_ID = "checkbox";
		const [appendCustomer, setappendCustomer] = useState(customerHiddenColumns);
		const [appendVehicle, setAppendVehicle] = useState(vehicleHiddenColumns);

		const [customerCol, setCustomerCol] = useState(customerColumn);
		const [vehicleCol, setVehicleCol] = useState(vehicleColumn);

		const [pushCustomer, setPushCustomer] = useState(false);
		const [pushVehicle, setPushVehicle] = useState(false);

		const [numberOfRows, setNumberOfRows] = useState({
			value: 100,
			label: "100",
		});

		const [pageSelect, setPageSelect] = useState({
			value: 0,
			label: "Page 1",
		});

		//drop down option for customer
		const customerOptions = useMemo(() => {
			const options = new Set();
			customerHiddenColumns.forEach((element) => {
				options.add(element);
			});
			return [...options.values()];
		}, [customerHiddenColumns]);

		const handleCustomerDropDown = (e) => {
			const val = e.target.value;
			if (!pushCustomer) {
				customerHiddenColumns.push(customerColumn);
				setPushCustomer(true);
			}
			const filtered = customerHiddenColumns.filter((value) => value !== val);
			setappendCustomer(filtered);
			const newHiddenCol = filtered.concat(appendVehicle);
			setHiddenColumns(newHiddenCol);
			setCustomerCol(val);
		};

		//drop down option for vehicle
		const vehicleOptions = useMemo(() => {
			const options = new Set();
			vehicleHiddenColumns.forEach((element) => {
				options.add(element);
			});
			return [...options.values()];
		}, [vehicleHiddenColumns]);

		const handleVehicleDropdown = (e) => {
			const val = e.target.value;
			if (!pushVehicle) {
				vehicleHiddenColumns.push(vehicleColumn);
				setPushVehicle(true);
			}
			const filtered = vehicleHiddenColumns.filter((value) => value !== val);
			setAppendVehicle(filtered);
			const newHiddenCol = filtered.concat(appendCustomer);
			setHiddenColumns(newHiddenCol);
			setVehicleCol(val);
		};

		const fuzzyTextFilterFn = (rows, id, filterValue) => {
			return matchSorter(rows, filterValue, {
				keys: [(row) => row.values[id]],
			});
		};

		// Let the table remove the filter if the string is empty
		fuzzyTextFilterFn.autoRemove = (val) => !val;

		// Define a custom filter filter function!
		const filterGreaterThan = (rows, id, filterValue) => {
			return rows.filter((row) => {
				const rowValue = row.values[id];
				return rowValue >= filterValue;
			});
		};

		// This is an autoRemove method on the filter function that
		// when given the new filter value and returns true, the filter
		// will be automatically removed. Normally this is just an undefined
		// check, but here, we want to remove the filter if it's not a number
		filterGreaterThan.autoRemove = (val) => typeof val !== "number";

		//  Define a default UI for filtering
		const DefaultColumnFilter = ({
			column: { filterValue, preFilteredRows, setFilter },
		}) => {
			const count = preFilteredRows.length;

			return (
				<FormGroup>
					<Input
						value={filterValue || ""}
						placeholder={`Search ${count} records...`}
						type="text"
						onChange={(e) => {
							setFilter(e.target.value || undefined);
						}}
					/>
				</FormGroup>
			);
		};

		const filterTypes = useMemo(
			() => ({
				// Add a new fuzzyTextFilterFn filter type.
				fuzzyText: fuzzyTextFilterFn,
				// Or, override the default text filter to use
				// "startWith"
				text: (rows, id, filterValue) => {
					return rows.filter((row) => {
						const rowValue = row.values[id];

						return rowValue !== undefined
							? String(rowValue)
									.toLowerCase()
									.startsWith(String(filterValue).toLowerCase())
							: true;
					});
				},
			}),
			[]
		);

		const defaultColumn = useMemo(
			() => ({
				// Let's set up our default Filter UI
				Filter: DefaultColumnFilter,
			}),
			[]
		);

		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
			nextPage,
			pageOptions,
			previousPage,
			canPreviousPage,
			canNextPage,
			setPageSize,
			gotoPage,
			setHiddenColumns,
			selectedFlatRows,
		} = useTable(
			{
				columns,
				data,
				defaultColumn, // Be sure to pass the defaultColumn option
				filterTypes,
				initialState: {
					pageSize: 100,
					pageIndex: 0,
					hiddenColumns: hiddenColumns,
				},
				getRowId: (row, relativeIndex) => {
					return row.id || relativeIndex;
				},
			},
			useFilters,
			useSortBy,
			usePagination,
			useRowSelect,
			(hooks) => {
				hooks.allColumns.push((columns) => {
					if (checkboxColumn === true) {
						return [
							{
								id: CHECKBOX_ID,
								// The header can use the table's getToggleAllRowsSelectedProps method
								// to render a checkbox
								Header: ({ getToggleAllRowsSelectedProps }) => (
									<div>
										<IndeterminateCheckbox
											{...getToggleAllRowsSelectedProps()}
										/>
									</div>
								),
								// The cell can use the individual row's getToggleRowSelectedProps method
								// to the render a checkbox
								Cell: ({ row }) => (
									<div onClick={(e) => e.stopPropagation()}>
										<IndeterminateCheckbox
											{...row.getToggleRowSelectedProps()}
										/>
									</div>
								),
							},
							...columns,
						];
					}

					return [...columns];
				});
			}
		);

		// We don't want to render all of the rows for this example, so cap
		// it for this use case
		// const firstPageRows = rows.slice(0, 10);
		let pageSelectData = Array.apply(null, Array(pageOptions.length));

		const numberOfRowsData = [5, 10, 20, 25, 50, 100];

		useEffect(() => {
			if (setSelectedRows != null) {
				setSelectedRows(selectedFlatRows.map((row) => ({ ...row.original })));
			}
			// eslint-disable-next-line
		}, [JSON.stringify(selectedFlatRows)]);

		// // Function to determine the row class based on condition
		// const getRowClassName = (row) => {
		// 	console.log(conditionalRow(row.original));
		// 	if (conditionalRow(row.original)) {
		// 		return "row-pink"; // Apply pink class if condition is met
		// 	}
		// 	return ""; // Default case (no class)
		// };
		return (
			<>
				<div
					style={{ gap: "5px", alignItems: "center" }}
					className="d-flex justify-content-center mb-2"
				>
					<Button
						onClick={() => previousPage()}
						readOnly={!canPreviousPage}
						className="btn-link btn-sm"
					>
						<i className="nc-icon nc-minimal-left text-center" />
					</Button>
					<div style={{ gap: "5px" }} className="d-flex no-wrap ">
						<Select
							className="react-select primary d-block"
							classNamePrefix="react-select"
							name="pageSelect"
							value={pageSelect}
							onChange={(value) => {
								gotoPage(value.value);
								setPageSelect(value);
							}}
							options={pageSelectData.map((_prop, key) => {
								return {
									value: key,
									label: "Page " + (key + 1),
								};
							})}
							placeholder="Choose Page"
						/>
						<Select
							className="react-select primary"
							classNamePrefix="react-select"
							name="numberOfRows"
							value={numberOfRows}
							onChange={(value) => {
								setPageSize(value.value);
								setNumberOfRows(value);
							}}
							options={numberOfRowsData.map((prop) => {
								return {
									value: prop,
									label: prop,
								};
							})}
							placeholder="Choose Rows"
						/>
					</div>

					<div className="actions-right">
						<Button
							onClick={() => nextPage()}
							readOnly={!canNextPage}
							className="btn-link btn-sm"
						>
							<i className="nc-icon nc-minimal-right  text-center" />
						</Button>
					</div>
				</div>
				<hr className="mb-3 mx-auto w-50" />
				<div className="rt-resizable-header-content ReactTable -striped -highlight">
					<table {...getTableProps()} className="rt-table">
						<thead className="rt-thead -header">
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
									{headerGroup.headers.map((column) => (
										<th
											key={column.id}
											className={classnames("rt-th rt-resizable-header", {
												"-cursor-pointer": column.canFilter,
												"-sort-asc": column.isSorted && !column.isSortedDesc,
												"-sort-desc": column.isSorted && column.isSortedDesc,
												"-check-box": column.id === CHECKBOX_ID,
											})}
										>
											<div
												className="rt-resizable-header-content"
												{...(column.canFilter && {
													...column.getHeaderProps(
														column.getSortByToggleProps()
													),
												})}
											>
												{column.render("Header")}
											</div>

											{/* customer and vehicle drop down options sorted */}
											<div>
												{column.customerSort && isDealTable ? (
													<FormGroup>
														<Select
															type="select"
															value={customerCol}
															onChange={(e) => {
																handleCustomerDropDown(e);
																column.setFilter("");
															}}
														>
															<option value={customerColumn}>Customer</option>
															{customerOptions.map((option, i) => (
																<option key={i} value={option}>
																	{option}
																</option>
															))}
														</Select>
													</FormGroup>
												) : null}

												{column.vehicleSort && isDealTable ? (
													<FormGroup>
														<Input
															type="select"
															value={vehicleCol}
															onChange={(e) => {
																handleVehicleDropdown(e);
																column.setFilter("");
															}}
														>
															<option value={vehicleColumn}>Vehicle</option>
															{vehicleOptions.map((option, i) => (
																<option key={i} value={option}>
																	{option}
																</option>
															))}
														</Input>
													</FormGroup>
												) : null}
											</div>
											{/* Render the columns filter UI */}
											{column.canFilter ? column.render("Filter") : null}
										</th>
									))}
								</tr>
							))}
						</thead>

						<tbody {...getTableBodyProps()} className="rt-tbody">
							{page.map((row, i) => {
								prepareRow(row);
								const rowClassName = conditionalRow(row.original);
								return (
									<tr
										{...row.getRowProps({
											onClick: () => onRowClick && onRowClick(row.original),
											onDoubleClick: () =>
												onRowDoubleClick && onRowDoubleClick(row.original),

											style: { cursor: "pointer" },
										})}
										className={classnames(
											"rt-tr",
											rowClassName,
											rowClassName === "" && {
												" -odd": i % 2 === 0,
												" -even": i % 2 === 1,
											}
										)}
									>
										{row.cells.map((cell) => (
											<td
												{...cell.getCellProps([
													{
														style: cell.column.style,
														className: classnames("rt-td singleCell", {
															"-check-box": cell.column.id === CHECKBOX_ID,
															// ... other class names
														}),
														onClick: (e) => {
															// If this cell is interactive, stop propagation
															if (cell.column.id === "actions") {
																e.stopPropagation();
															}
														},
													},
												])}
											>
												{cell.render("Cell")}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div
					style={{ gap: "18px", alignItems: "center" }}
					className="d-flex justify-content-center mt-5 mb-4"
				>
					<div>
						<Button
							onClick={() => previousPage()}
							readOnly={!canPreviousPage}
							className="btn-link btn-sm"
						>
							<i className="nc-icon nc-minimal-left text-center" />
						</Button>
					</div>
					<div style={{ gap: "18px" }} className="d-flex no-wrap">
						<Select
							className="react-select primary d-block"
							classNamePrefix="react-select"
							name="pageSelect"
							value={pageSelect}
							onChange={(value) => {
								gotoPage(value.value);
								setPageSelect(value);
							}}
							options={pageSelectData.map((_prop, key) => {
								return {
									value: key,
									label: "Page " + (key + 1),
								};
							})}
							placeholder="Choose Page"
						/>
						<Select
							className="react-select primary"
							classNamePrefix="react-select"
							name="numberOfRows"
							value={numberOfRows}
							onChange={(value) => {
								setPageSize(value.value);
								setNumberOfRows(value);
							}}
							options={numberOfRowsData.map((prop) => {
								return {
									value: prop,
									label: prop,
								};
							})}
							placeholder="Choose Rows"
						/>
					</div>

					<div className="">
						<Button
							onClick={() => nextPage()}
							readOnly={!canNextPage}
							className="btn-link btn-sm"
						>
							<i className="nc-icon nc-minimal-right  text-center" />
						</Button>
					</div>
				</div>
			</>
		);
	}
	// },
	// // Memoization function to determine if the component should re-render
	// (prevProps, nextProps) => {
	// 	console.log(prevProps.data, nextProps.data);
	// 	return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
	// }
);

export default ReactTable;

// Define PropTypes for the component
ReactTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	vehicleHiddenColumns: PropTypes.array,
	customerHiddenColumns: PropTypes.array,
	hiddenColumns: PropTypes.array,
	customerColumn: PropTypes.string,
	vehicleColumn: PropTypes.string,
	isDealTable: PropTypes.bool,
	checkboxColumn: PropTypes.bool,
	setSelectedRows: PropTypes.func,
};
