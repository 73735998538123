import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import useCurrentSaleStore from "stores/SaleStore";
import { getZipAndCustomTaxRate } from "api/DealAPI";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { DealStatus } from "constants/Constants";
import dayjs from "dayjs";
import { showApiError } from "utils/errorRoutingUtils";
import shallow from "zustand/shallow";

/**
 * TaxCountySelector component to display and select tax rates by county.
 *
 * @returns {JSX.Element} The rendered component
 */
const TaxCountySelector = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const dealerZip = useFromUser("locations")["zipCode"];
	const dealerCity = useFromUser("locations")["city"];
	const dealerCounty = useFromUser("locations")["county"];
	const [taxRatesList, setTaxRatesList] = useState([]);

	const {
		type,
		county,
		editCounty,
		countyRecNum,
		editCountyRecNum,
		taxDefaults,
		buyerCity,
		buyerCounty,
		buyerZip,
		editTaxDefaultsValue,
	} = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			county: state.county,
			editCounty: state?.editCounty,
			countyRecNum: state.deal.countyRecNum,
			editCountyRecNum: state.editCountyRecNum,
			taxDefaults: state?.deal?.dealSettings.taxDefaults,
			buyerCounty: state?.buyerAddress?.county,
			buyerCity: state?.buyerAddress?.city,
			buyerZip: state?.buyerAddress?.zip,
			editTaxDefaultsValue: state.editTaxDefaultsValue,
		}),
		shallow
	);

	const { editTaxRate } = useCurrentSaleStore(
		(state) => ({
			editTaxRate: state.editTaxRate,
		}),
		shallow
	);

	/**
	 * Fetches the zip and custom tax rates.
	 *
	 * @param {string} zip - The zip code to fetch the tax rates for.
	 */
	const ZipAndCustomTaxRate = (zip) => {
		const cRecnum = countyRecNum == null ? 0 : countyRecNum;
		getZipAndCustomTaxRate(dealerID, locationID, zip, cRecnum).then(
			(res) => {
				const formattedRatesList = formatTaxRates(res.data.content);
				setTaxRatesList(formattedRatesList);

				if (!(taxDefaults?.taxRegion === 2 && taxDefaults?.taxRate > 0)) {
					if (taxDefaults?.taxRegion === 1 || taxDefaults?.taxRegion === 0) {
						getCountyRecNum(taxDefaults?.taxRegion, formattedRatesList);
					} else {
						handleSelect(null, formattedRatesList);
					}
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	/**
	 * Formats the tax rates into a list of options.
	 *
	 * @param {Array} taxRates - The tax rates to format.
	 * @returns {Array} The formatted tax rates.
	 */
	const formatTaxRates = (taxRates) => {
		const options = taxRates.map((tax) => {
			const rate = Number(parseFloat(tax.taxRate) * 100).toFixed(4);
			return {
				city: tax.city,
				label: `${tax.zip} - ${tax.city}, ${tax.state} - ${rate}%`,
				value: tax.recNum,
				taxRate: tax.taxRate,
				obj: tax,
			};
		});
		return [
			...options,
			{
				city: "",
				label: "None",
				value: "",
				taxRate: 0,
				obj: null,
			},
		];
	};

	/**
	 * Handles the selection of a tax rate.
	 *
	 * @param {string|number} ID - The selected tax rate ID.
	 * @param {Array} list - The list of tax rates.
	 */
	const handleSelect = (ID, list) => {
		const localTaxRatesList = list || taxRatesList;
		if (ID === "" || ID === null) {
			setNullsToStore();
		} else if (ID) {
			const rateObj = localTaxRatesList.find(
				(rate) => rate.value === Number(ID)
			);
			if (rateObj) {
				editCountyRecNum(Number(ID));
				editCounty(rateObj.obj);
				editTaxRate(rateObj.taxRate);
				editTaxDefaultsValue("countyRecNum", Number(ID));
				editTaxDefaultsValue("taxRate", rateObj.taxRate);
			}
		}
	};

	/**
	 * Sets default values to the store.
	 */
	const setNullsToStore = () => {
		editCountyRecNum(null);
		editCounty(null);
		editTaxRate(0);

		if (taxDefaults.taxRegion === undefined || taxDefaults.taxRegion === null) {
			taxDefaults.taxRegion = 0;
		}

		editTaxDefaultsValue("countyRecNum", null);
		editTaxDefaultsValue("taxRate", 0);
	};

	/**
	 * Gets the county record number based on the tax region and tax rates list.
	 *
	 * @param {number} taxRegion - The tax region.
	 * @param {Array} taxRatesList - The list of tax rates.
	 */
	const getCountyRecNum = (taxRegion, taxRatesList) => {
		console.log("taxRegion", taxRegion);
		console.log("taxRatesList", taxRatesList);

		let xCounty = "";

		let xCity = "";
		if (taxRegion === 0 && buyerZip && buyerZip.length === 5) {
			xCity = buyerCity;
			xCounty = buyerCounty;
		} else if (taxRegion === 2 && countyRecNum === null) {
			xCity = "";
			xCounty = buyerCounty;
		} else if (taxRegion === 1 && dealerZip) {
			xCity = dealerCity;
			xCounty = dealerCounty;
		}
		console.log("xCity", xCity);
		console.log("xCounty", xCounty);
		const taxRateValue = taxRatesList.find(
			(rate) =>
				rate.city.toUpperCase() === xCity.toUpperCase() &&
				rate.obj?.name.toUpperCase() === xCounty.toUpperCase()
		);
		console.log("taxRateValue", taxRateValue);
		if (!taxRateValue || taxRateValue.value === "") {
			setNullsToStore();
		} else {
			handleSelect(Number(taxRateValue.value), taxRatesList);
		}
	};

	useEffect(() => {
		let zips = dealerZip;
		if (buyerZip && buyerZip.length === 5) {
			zips = `${zips},${buyerZip}`;
		}
		ZipAndCustomTaxRate(zips);
		// eslint-disable-next-line
	}, [taxDefaults.taxRegion, buyerZip, dealerZip]);

	return (
		<FormGroup className="row-12 mb-0">
			<Label>
				{"Zip County Rate Last Updated: " +
					dayjs(county?.startDate).format("MM/DD/YYYY")}
			</Label>
			<Input
				name="taxRate"
				disabled={type !== DealStatus.PENDING || taxDefaults.taxRegion !== 2}
				type="select"
				value={countyRecNum === null ? "" : countyRecNum}
				onChange={(e) => handleSelect(e.target.value)}
			>
				{taxRatesList.map((rate) => (
					<option key={rate.value} value={rate.value}>
						{rate.label} - {rate.obj?.name}
					</option>
				))}
			</Input>
		</FormGroup>
	);
};

export default TaxCountySelector;
