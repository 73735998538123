import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "constants/Constants";
//import { LOCAL, LOCAL8081 } from "constants/Constants";

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/inv/",
	//	baseURL: LOCAL,
});
// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL,
// });

const imageInstance = createAxiosInstance({
	baseURL: userBaseUrl + "/inv/image/",
	headers: {
		"Cache-Control": "no-cache",
	},
});

export const getVehicleSettings = async (locationID) => {
	return await instance.get(`inventoryDefault/${locationID}`);
};
export const saveVehicleSettings = async (body) => {
	return await instance.post(`inventoryDefault/save`, body);
};
export const getNewStockNo = async (locationID) => {
	return await instance.get(`inventoryDefault/getRunningStock/${locationID}`);
};

export const getVehicleDetails = async (inventoryID) => {
	return await instance.get(`${inventoryID}`);
	//return await localInstance.get(`${inventoryID}`);
};

export const getVehicleDetailsByStockNo = async (locationID, stockNo) => {
	return await instance.get(`stockNo/${locationID}/${stockNo}`);
	//return await localInstance.get(`stockNo/${locationID}/${stockNo}`);
};

//Eventually this endpoint will need to be updated to the DCTableVehicle version
export const getVehicleList = async (dealerID, locationID) => {
	return await instance.get(`list/${dealerID}/${locationID}`);
	//return await localInstance.get(`list/${dealerID}/${locationID}`);
};

export const getVehicleListByStatus = async (dealerID, locationID, status) => {
	return await instance.get(`list/${dealerID}/${locationID}/${status}`);
	//return await localInstance.get(`list/${dealerID}/${locationID}/${status}`);
};
export const getVehicleListByDateRange = async (
	locationID,
	status,
	fromDate,
	toDate
) => {
	return await instance.get(
		`listByDateRange/${locationID}/${status}?fromDate=${fromDate}&toDate=${toDate}`
	);
	// return await localInstance.get(
	// 	`listByDateRange/${locationID}/${status}?fromDate=${fromDate}&toDate=${toDate}`
	// );
};
export const getVehicleListBySearch = async (locationID, filter) => {
	return await instance.post(`ListBySearch/${locationID}`, { filter });
	//return await localInstance.post(`ListBySearch/${locationID}`, { filter });
};

export const saveVehicle = async (body) => {
	return await instance.post(`save`, body);
	//return await localInstance.post(`save`, body);
};

export const deleteVehicle = async (ID) => {
	return await instance.get(`delete/${ID}/0`);
	//return await localInstance.get(`delete/${ID}/0`);
};
export const removeVehicle = async (ID) => {
	return await instance.get(`delete/${ID}/1`);
	//return await localInstance.get(`delete/${ID}/1`);
};

export const getReconditioningList = async (inventoryID) => {
	return await instance.get(`cost/list/${inventoryID}`);
};

export const getLocationsList = async (locationID) => {
	return await instance.get(`list/Location/${locationID}`);
};

export const getDescriptionlist = async (locationID) => {
	return await instance.get(`cost/list/description/${locationID}`);
};

export const getDepartmentlist = async (locationID) => {
	return await instance.get(`cost/list/department/${locationID}`);
};

export const getReconditionByID = async (ID) => {
	return await instance.get(`cost/${ID}`);
};

export const saveReconditioning = async (body) => {
	return await instance.post(`cost/save`, body);
};

export const deleteReconditioning = async (ID) => {
	return await instance.post(`cost/delete/${ID}`, null);
};

export const getInventoryFeed = async (locationID) => {
	return await instance.get(`list/feed/${locationID}`);
};

export const getImageList = async (dealerID, locationID, vin) => {
	return await instance.get(`image/list/${dealerID}/${locationID}/${vin}`);
};

export const uploadImages = async (
	dealerID,
	locationID,
	vin,
	files,
	setProgress
) => {
	const imageUploadInstance = createAxiosInstance({
		baseURL: userBaseUrl + "/inv/image/upload",
		//baseURL: LOCAL + "/image/upload",
		headers: {
			"Content-Type": "multipart/form-data",
			"Cache-Control": "no-cache",
		},
		onUploadProgress: (progressEvent) => {
			let { loaded, total } = progressEvent;
			let percentCompleted = Math.round((loaded * 100) / total);

			setProgress(percentCompleted);
		},
	});

	return await imageUploadInstance.post(
		`${dealerID}/${locationID}/${vin}`,
		files
	);
};

export const deleteImages = async (dealerID, locationID, vin, index) => {
	return await imageInstance.post(
		`delete/${dealerID}/${locationID}/${vin}/${index}`,
		null
	);
};

export const reorderImages = async (dealerID, locationID, vin, order) => {
	return await imageInstance.get(
		`reorder/${dealerID}/${locationID}/${vin}/${order}`
	);
};

export const getAutoInventoryDetails = async (inventoryID) => {
	return await instance.get(`auto/${inventoryID}`);
	//return await localInstance.get(`auto/${inventoryID}`);
};

export const getAllInvDetails = async (dealerID, locationID, inventoryID) => {
	return await instance.get(`info/${dealerID}/${locationID}/${inventoryID}`);
	// return await localInstance.get(
	// 	`info/${dealerID}/${locationID}/${inventoryID}`
	// );
};

export const updateSendFeed = async (locationID, listVehicleFeed) => {
	return await instance.post(`list/feed/update/${locationID}`, listVehicleFeed);
};

export const saveNewVehicleWithImages = async (body) => {
	return await instance.post(`save/new`, body);
	//return await localInstance.post(`save/new`, body);
};

export const getInventoryMakeList = async () => {
	return await instance.get(`makeList`);
};

export const getInventoryModelList = async () => {
	return await instance.get(`modelList`);
};

export const getMakeSpecificModels = async (make) => {
	return await instance.get(`makeList/${make}`);
};
export const getInventorySpecificModel = async (model) => {
	return await instance.get(`modelList/${model}`);
};

export const getAvailableVehicleByVin = async (vin, locationID, dealerID) => {
	return await instance.get(`vin/${vin}/${locationID}/${dealerID}`);
};

export const checkValidateDelete = async (inventoryId) => {
	return await instance.get(`validateDelete/${inventoryId}`);
};

export const removeVehicleFromDeal = async (inventoryId, dealID) => {
	return await instance.post(`deleteByDealId/${inventoryId}`, dealID);
};
export const getFlooringRatio = async (dealerID, locationID) => {
	return await instance.get(`flooringRatio/${dealerID}/${locationID}`);
};

//EquipmentListApi's
export const getCustomEquipmentList = async (locationID, status) => {
	return await instance.get(`equipmentList/${locationID}/${status}`);
};
export const saveToCustomEquipmentList = async (body) => {
	return await instance.post(`saveToEquipmentList`, body);
};
export const deleteFromCustomEquipmentList = async (id) => {
	return await instance.get(`deleteEquipmentList//${id}`);
};

//management controller apis
export const getDealerMakeList = async (dealerID, locationID) => {
	return await instance.get(`/manage/dealerMakeList/${dealerID}/${locationID}`);
};
export const getDealerModelList = async (dealerID, locationID) => {
	return await instance.get(
		`/manage/dealerModelList/${dealerID}/${locationID}`
	);
};
export const getDealerCustomMakesModels = async (dealerID, locationID) => {
	return await instance.get(
		`/manage/dealerCustomMakesModels/${dealerID}/${locationID}`
	);
};
export const saveMake = async (body) => {
	return await instance.post(`/manage/save/make`, body);
};
export const saveModel = async (body) => {
	return await instance.post(`/manage/save/model`, body);
};
export const deleteCustomMake = async (id) => {
	return await instance.get(`/manage/delete/make/${id}`);
};
export const deleteCustomModel = async (id) => {
	return await instance.get(`/manage/delete/model/${id}`);
};

export const getVehicleListisAdvertised = async (locationId) => {
	return await instance.get(`list/isAdvertised/${locationId}`);
};
export const updateIsAdvertised = async (locationID, listVehicleFeed) => {
	return await instance.post(
		`list/updateIsAdvertised/${locationID}`,
		listVehicleFeed
	);
};
